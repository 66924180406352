/* 기본정보 스타일 */
.form-container {
  max-width: 65%;
  margin: auto;
  padding: 10px;
  background-color: #fff;
}

@media (max-width: 768px) {

  /* 768px 이하의 화면 크기일 때 */
  .form-container {
    max-width: 95%;
  }
}

/* 기본 로고 스타일 - PC 환경 */
.logo-container img {
  width: 350px;
  height: auto; /* 가로 비율에 맞춰 자동으로 높이를 설정 */
}

/* 모바일 환경에서 로고 크기 조정 */
@media (max-width: 768px) {
  .logo-container img {
    width: 300px;
    height: auto; /* 가로 비율에 맞춰 자동으로 높이를 설정 */
    margin-bottom: -20px;
    margin-top: -20px;
  }
}



.welcome-container {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  /* 각 필드 간의 간격 추가 */
  margin-bottom: -15px;
}

.form-field {
  flex: 1;
  margin: 10px 0;
  /* 상하 마진 추가 */
  min-width: calc(33.3333% - 13.3333px);
  /* 3개 아이템이 한 줄에 들어가도록 계산 (gap 반영) */
}

@media (max-width: 768px) {
  .form-field {
    flex: 1 1 100%; /* 작은 화면에서는 한 줄에 한 개씩 배치 */
    min-width: 100%; /* 필드를 한 줄에 하나씩 배치 */
  }
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  /* 버튼을 양쪽에 배치 */
  margin-top: 20px;
}

.policy-container {
  height: 160px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 0px;
  background-color: #f9f9f9;
}

.policy-content {
  white-space: pre-wrap;
  background-color: #FFFFFF;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
  margin-bottom: 10px;
  background-color: #FDF3DB;
}

.personal-info-container {
  background-color: #fff;
  padding: 0px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.title {
  margin: 0;
}

.checkbox-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0px;
}

/* 기본 스타일 - PC 및 모바일 환경 */
.category-item {
  padding: 10px 0;
  border-bottom: 1px solid #ddd; /* 각 줄마다 선 추가 */
}

.category-item:last-child {
  border-bottom: none; /* 마지막 항목에는 선을 표시하지 않음 */
}

.category-description {
  margin-top: 5px; /* 설명과 제목 사이에 약간의 간격 추가 */
  text-align: left;
}
/* 상담분류 스타일 */
.category-selection-container {
  background-color: #fff;
  padding: 0px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.category-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
  margin-bottom: 10px;
  background-color: #FDF3DB;
}

.category-title-container2 {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
  margin-bottom: 10px;
  background-color: #FDF3DB;
}

.category-form-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: -15px;
}



.category-item .MuiFormControlLabel-root {
  flex: 1;
}

.category-description {
  flex: 2;
}

.category-form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}


.date-form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* 접수 완료 스타일 */
.confirmation-container {
  background-color: #fff;
  padding: 0px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.confirmation-title {
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  padding-left: 10px;
  background-color: #FDF3DB;
}

.confirmation-message {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 4px;
  line-height: 1.8;
}

/* 날짜 및 시간 선택 스타일 */
.date-selection-container {
  background-color: #fff;
  padding: 0px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.date-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
  margin-bottom: 10px;
  background-color: #FDF3DB;
}

.date-time-selection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (min-width: 768px) {
  .date-time-selection {
    flex-direction: row;
  }
}


.calendar-container {
  flex: 2;
  padding-right: 40px;
  /* 달력과 시간 슬롯 사이의 간격 */
}

.time-slots-container {
  flex: 2;
  padding-left: 20px;
  padding-top: 20px;
  /* 달력과 시간 슬롯 사이의 간격 */
}

.time-slot-grid {
  margin-top: 30px;
}


.time-slot-button {
  margin: 15px 0;
  /* 위아래 간격 조절 */
}


/* 상담 방법 선택 스타일 */
.visit-or-not-selection-container {
  background-color: #fff;
  padding: 0px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.visit-or-not-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
  margin-bottom: 10px;
/*  background-color: #f5f5f5; */
background-color: #FDF3DB;

}

.visit-option-group {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  /* 세로 정렬을 중앙으로 맞춤 */
  justify-content: space-around;
  /* 또는 center */
  margin-bottom: 20px;
  /* 하단 여백 유지 */
  margin-left: 20px;
  /* 하단 여백 유지 */
}

.visit-option-group label {
  margin-right: 10px;
  /* 라디오 버튼과 텍스트 사이의 간격을 조정 */
}

.visit-option-group input[type="radio"] {
  margin-right: 5px;
  /* 라디오 버튼과 라벨 사이의 간격을 조정 */
}


.visit-form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}